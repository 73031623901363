import React from 'react';
import { bindings, hook } from '@vl/redata';
import useLoadingModel from '@vl/hooks/useLoadingModel';
import useRoute from '@vl/hooks/useGbRouteDe';
import usePromiseSource from '@vl/hooks/usePromiseSource';
import { getClient, gql } from '@vl/mod-clients/hasuraApp';
import _ from 'lodash';

const bindData = bindings({
  pageContainer: {
    rules: [
      [
        'data',
        {
          data: {
            loadingModel: hook(useLoadingModel(() => ({ fetchProfile: false }))),
            getId: hook(() => {
              const route = useRoute();
              const paramsData = _.get(route, 'params.id', '');
              return paramsData;
            }),
            '@item': hook((ctx) => {
              const course_id = ctx.get('getId');
              const data = usePromiseSource(
                async () => {
                  try {
                    if (!course_id) return null;
                    const client = await getClient();
                    const data = await client.request(
                      gql`
                        query GetCourseInfo($id: uuid!) {
                          b2b_course_by_pk(id: $id) {
                            name
                          }
                        }
                      `,
                      { id: course_id }
                    );
                    return data;
                  } catch (err) {
                    console.log('error', err);
                  }
                },
                null,
                [course_id]
              );
              return _.get(data, 'b2b_course_by_pk', {});
            }),
          },
        },
      ],
    ],
  },
  isLoading: {
    rules: [
      [
        'display',
        {
          display: hook((ctx) => {
            return ctx.get('authModel.isLoadingRedirectResult');
          }),
        },
      ],
    ],
  },
  // notLoading: {
  //   rules: [
  //     [
  //       'display',
  //       {
  //         display: hook((ctx) => !ctx.apply('loadingModel.isLoading')),
  //       },
  //     ],
  //   ],
  // },
});

export default bindData;
