import React from 'react';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { ctx } from '@vl/redata';
import { UnAuthRedirect } from '@uz/unitz-tool-components/AppRedirect';
import Button from '@uz/unitz-components-web/Button';
import Link from '@uz/unitz-components-web/Link';
import { Result, Row, Col } from 'antd';
import gstyles from '@vl/gstyles';
import { ResponsiveProps } from '@uz/unitz-components-web/Responsive';

const Index = () => {
  // @TODO: validate missing required input
  return (
    <DIV className="pageContainer">
      <UnAuthRedirect to={ctx.apply('authModel.generateLoginRedirectUrl')}>
        <div className="p-4">
          <div className="mb-3 text-main uppercase font-semibold text-base">
            {ctx.apply('i18n.t', 'ZoomToolCourse.add_form_title')}
          </div>
          <div className="p-6  bg-white500 rounded-lg shadow-md">
            <Result
              status="success"
              icon={
                <div className="flex justify-center items-center">
                  {gstyles.icons({ name: 'check-success', size: 88 })}
                </div>
              }
              title={
                <div className="mb-4 w-full md:w-2/3 mx-auto text-brandb500 text-2xl md:text-4xl font-semibold">
                  {ctx.apply('i18n.t', 'ZoomToolCourse.success_title')} {ctx.get('@item.name')}
                </div>
              }
              subTitle={
                <div className="text-sub text-base text-center">
                  {ctx.apply('i18n.t', 'ZoomToolCourse.success_sub_title')}
                </div>
              }
            />

            <Row justify="end">
              <Col xs={24} md={12}>
                <ResponsiveProps
                  xs={{ style: { flexDirection: 'column-reverse' } }}
                  md={{ style: { flexDirection: 'row' } }}
                >
                  {(resProps) => (
                    <Row gutter={[16, 16]} {...resProps}>
                      <Col xs={24} md={12}>
                        <Link to={ctx.apply('routeStore.toUrl', 'toolAccountCourses')}>
                          <Button name="ink" className="w-full" loading={ctx.get('form.isSubmitting')}>
                            <span id="button-text">
                              {ctx.apply('i18n.t', 'ZoomToolCourse.Action.manage_all_courses')}
                            </span>
                          </Button>
                        </Link>
                      </Col>
                      <Col xs={24} md={12}>
                        <Link to={ctx.apply('routeStore.toUrl', 'toolAccountCourseDetail', { id: ctx.get('getId') })}>
                          <Button type="primary" className="w-full">
                            <span id="button-text">{ctx.apply('i18n.t', 'ZoomToolCourse.Action.view_course')}</span>
                          </Button>
                        </Link>
                      </Col>
                    </Row>
                  )}
                </ResponsiveProps>
              </Col>
            </Row>
          </div>
        </div>
      </UnAuthRedirect>
    </DIV>
  );
};

export default displayName(Index);
